.App {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
}

.todoList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.inputContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-item-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.buttons {
  width: 400px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 30px;
}

.timer {
  text-align: left;
  width: 200px;
  border: 1px solid lightgray;
  padding: 10px;
  margin: 0;
}

.timer-container {
  justify-content: center;
  display: flex;
  background-color: white;
}

.new-item {
  background-color: rgb(249, 249, 249);
  width: 100%;
  padding: 10px;
  -webkit-box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
  -moz-box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
  box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
}

.time-range {
  width: 80%;
}

.bottom-form {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-around;
}
