.task {
  display: flex;
  width: 450px;
  background-color: rgb(249, 249, 249);
  flex-direction: row;
  height: fit-content;
  padding: 15px;
  // cursor: move;
  margin: 20px;
  -webkit-box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
  -moz-box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
  box-shadow: 10px 10px 10px -3px rgba(227, 227, 227, 1);
  position: relative;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;

  &.active {
    width: 500px;
    -webkit-box-shadow: 15px 15px 15px 3px rgba(227, 227, 227, 1);
    -moz-box-shadow: 15px 15px 15px 3px rgba(227, 227, 227, 1);
    box-shadow: 15px 15px 15px 3px rgba(227, 227, 227, 1);
  }

  .increase {
    cursor: pointer;
    padding: 0 5px;
  }
  .decrease {
    cursor: pointer;
    padding: 0 5px;
  }

  .content {
    font-weight: bold;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    width: 90%;
    text-align: left;
    font-size: 20px;
  }

  .title {
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
  }

  .time {
    border: 1px solid yellowgreen;
    padding: 0 10px;
    margin-bottom: 10px;
    // border-radius: 20px;
    width: fit-content;
  }

  .status {
    display: flex;
    height: 30px;
    align-items: center;
    border: 1px solid gray;
    padding: 2px 5px;
    color: gray;
    font-size: 10px;
    border-radius: 10px;
    width: fit-content;
    &.active {
      border: 1px solid yellowgreen;
      color: yellowgreen;
    }
  }

  .url {
    // border: 1px solid yellowgreen;
    // padding: 0 10px;
    margin: 10px 0;
    border-radius: 20px;
    width: fit-content;
    a {
      text-decoration: none;
    }
  }
  .progress-container {
    // width: 100%;
  }
}

.delete {
  border-radius: 20px;
  max-width: 15px;
  max-height: 15px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  background-color: rgb(177, 42, 42);
  border: 0;
  padding: 5px;
  font-size: 8px;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.task-buttons {
  display: flex;
  flex-direction: column;
}
